import React from 'react';
import './App.css';
const App: React.FC = () => {
  return (
    <div id="scheduleMfeRoot">
      <div className="schedule-Container">
        <h1>SCHEDULE MFE WEBAPP</h1>
      </div>
    </div>
  );
};

export default App;
